<template>
  <div>
    <li v-for="chat in chats" :key="chat._id">
      <ChatItem :chat="chat"></ChatItem>
    </li>
  </div>
</template>
<script>
import ChatItem from './ChatItem.vue';

import { mapGetters } from "vuex";

export default {
  name: "ChatLists",
  data() {
    return {
      chats: [],
      chats_unread: [],
    }
  },
  methods:
  {
    getLastMessage(messages) {
      let last_message = messages[messages.length - 1];
      return last_message.message;
    },
    getLastMessageAuthor(chat) {
      if (chat.users.length > 1) {
        let author = '',
          message_count = chat.messages.length;

        chat.users.map((cur) => {
          if (cur.user_id === chat.messages[message_count - 1].user_id) {
            author = cur.user_data.name;
          }
        })

        return author;
      }

      return 'You';
    }
  },
  mounted() {
    this.chats = this.getChats;
  },
  computed: {
    ...mapGetters(['get_flag_for_last_chat', 'getChats', 'getRender']),
    getRender() {
      this.chats = this.getChats;
    },
    user() {
      return this.$store.state.key.user
    }
  },
  components: {
    ChatItem
  }
}
</script>